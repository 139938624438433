import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import JobLink from './jobLink'
import {Container, Row} from 'reactstrap'
import Slider from "react-slick";
import styled from 'styled-components'
import Link from './link'
import Img from 'gatsby-image'
import {ukSite} from './utils'

let StyledSpan = styled.span`
  font: normal  20px / 22px "Kadwa", Helvetica, Arial, Verdana, sans-serif
`

const MockRow = ({title, image}) => (
  <Link to="/mechanics/jobs/independent-repairers/" className="d-flex h-100">
    <div className="d-flex p-1">
      <div className="p-2 p-md-5 text-center border d-flex align-items-center flex-column justify-content-center">
        {image ? <Img fixed={image.childImageSharp.fixed} className="mb-4"/> : ''}
        <h2 style={{textTransform: 'uppercase'}}>{title}</h2>
        <hr/>
        <StyledSpan className="text-primary">Jobs Available</StyledSpan>
      </div>
    </div>
  </Link>
)

const settings = {
  infinite: false,
  className: "center",
  centerPadding: "60px",
  speed: 500,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
  ]
};

let StyledSlider = styled(props => <Slider {...props}/>)`
  .slick-arrow {
    z-index: 10000;
    &.slick-next {
      right: -35px;
    }
    &.slick-prev {
      left: -35px;
    }
    &:before, &:after {
      color: ${props => props.theme.primary};
      font-size: 30px;
    }
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: {wordpress_parent: {eq: 132}}) {
        edges {
          node {
            title
            slug
            path
            wordpress_id
            featured_media {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 70) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      franchised: file(relativePath: { eq: "Franchised repairer workshop.jpeg" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      independent: file(relativePath: { eq: "Independent repairer workshop.jpg" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      manufacturer: file(relativePath: { eq: "Manufacturer specialist workshop.jpg" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <Container className="py-5 px-5">
      <StyledSlider {...settings}>
        {!ukSite() && <MockRow title="Independent Repairer Workshops" image={data.independent}/>}
        {!ukSite() && <MockRow title="Franchised Repairer Workshops" image={data.franchised}/>}
        {!ukSite() && <MockRow title="Manufacturer Specialist Workshops" image={data.manufacturer}/>}
        {data.allWordpressPage.edges
          .filter(n => n.node.featured_media != null && n.node.featured_media.localFile != null)
          .sort((a, b) => a.node.title.localeCompare(b.node.title))
          .reduce(function(result, value, i, array) {
            let results = [];
            for (i = 0; i < array.length; i += 3) results.push(array.slice(i, i + 3));
            return results;
          }, []).map((group, i) => (
            <div key={i}>
              {group.map(item => (
                <JobLink page={item.node}/>
              ))}
            </div>
        ))}
      </StyledSlider>
    </Container>
  )
}
