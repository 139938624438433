import React from "react"
import styled from 'styled-components'

let Box = styled.div`
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid white;
  height: 100%;
  a {
    text-decoration: none;
  }
  &:hover {
    border: 1px solid #89969E;
  }
`

export default ({children}) => (
  <Box>
    {children}
  </Box>
)
