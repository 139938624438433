import React from "react"
import Layout from "../../components/layout"
import HomeJobs from '../../components/homeJobs'
import website from '../../data/website'
import Slider from '../../components/slider'
import { graphql } from 'gatsby'

export default ({data}) => {
  if (!data.wordpressPage) {
    return <></>
  }
  const {title, content} = data.wordpressPage
  return (
    <Layout title={title}>
      <Slider/>
      <HomeJobs/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(path: {eq: "/job-seekers/jobs/"}) {
      title
      content
    }
  }
`
