import React from "react"
import Link from '../components/link'
import Box from "../components/box"
import Img from "gatsby-image/withIEPolyfill"
import styled from 'styled-components'

let StyledSpan = styled.span`
  font: normal  20px / 22px "Kadwa", Helvetica, Arial, Verdana, sans-serif
`

export default ({page}) => (
  <div>
    <Box>
      <Link to={page.path} className="d-flex flex-column" style={{height: '100%'}}>
        <div style={{margin: 'auto 0'}}>
          {page.featured_media ? <Img className="mb-4"
            fluid={page.featured_media.localFile.childImageSharp.fluid}
            alt={page.title}
            style={{maxHeight: '70px'}}
            objectPosition="50% 50%"
            objectFit="contain"
          /> : ''}
        </div>
        <div className="text-center d-flex flex-column justify-content-center align-items-center">
          <h5
            className="small mb-0"
            style={{textTransform: 'capitalize'}}
            dangerouslySetInnerHTML={{ __html: page.title.split('Jobs In Australia')[0].replace('Mechanic', 'Technicians')}}/>
          <hr className="my-2" style={{borderColor: "#89969E", width: '100%'}}/>
          <StyledSpan className="text-primary">Jobs Available</StyledSpan>
        </div>
      </Link>
    </Box>
  </div>
)
